input[type=number] {
    -moz-appearance: textfield;
}

.eyeicon {
    float: right;
    margin-top: -50px;
    position: relative;
    z-index: 2;
    margin-right: 10px;
}

.MuiDataGrid-cell--withRenderer {
    cursor: pointer;
}

.linkcreatediv {
    height: 100vh;
    overflow: scroll;
}

.linkcreatediv::-webkit-scrollbar {
    display: none;
}

.leftsidediv {
    height: 300px;
    overflow: scroll;
}

.leftsidediv::-webkit-scrollbar {
    display: none;
}

.rightside {
    height: 100vh;
    overflow: scroll;
}

.rightside::-webkit-scrollbar {
    display: none;
}

.ErrorHandling_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.ErrorHandling_logo {
    height: 30px;
}

.ErrorHandlingNav {
    background-color: rgb(236, 236, 236);
    padding: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ErrorHandling_content h1 {
    margin-left: 40px !important;
    letter-spacing: 5px;
    font-weight: bold;
    font-size: 25px;
    width: 70%;
    text-align: center;
}

.ErrorHandling_content h2 {
    margin-left: 40px !important;
    letter-spacing: 5px;
    font-weight: bold;
    font-size: 25px;
    text-align: center;
}

.okimg {
    width: 20%;
}

table,
th,
td {
    border: 1px solid black;
    padding: 10px;
}

th {
    color: black;
    font-weight: bold;
    font-size: 20px;
}

td {
    font-size: 15px;
}

.smalltext {
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.smalltextsubject {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.accountmodel {
    position: absolute;
    inset: 50% auto auto 50%;
    border: 1px solid rgb(204, 204, 204);
    background-color: white !important;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    height: 90%;
    margin-right: -50%;
    width: 50% !important;
    transform: translate(-50%, -50%);
    border: 1px solid lightgray !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.Movebatchmodel {
    position: absolute;
    inset: 50% auto auto 50%;
    border: 1px solid rgb(204, 204, 204);
    background-color: white !important;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    margin-right: -50%;
    width: 30% !important;
    transform: translate(-50%, -50%);
    border: 1px solid lightgray !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.jodit-workplace {
    height: 100px !important;
}

.messagediv {
    height: 200px;
    overflow-y: scroll;
}

.rangeslider {
    margin: 0;
}

.replyyag {
    height: 100px !important;
}

.sidebarheight {
    height: 100vh;
    overflow-y: scroll;
}

.sidebarheight::-webkit-scrollbar {
    display: none;
}