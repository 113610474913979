/* Ensure the calendar fits the full viewport height */
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /* font-family: Arial, sans-serif; */
}

#calendar-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

#calendar {
    flex: 1;
    overflow: hidden;
}

/* FullCalendar slot styles */
.fc .fc-timegrid-slot {
    border: 1px solid #ddd;
    height: 150px;
    /* Height for slots */
}

.fc-timegrid-event .fc-event-main {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 14px;
    color: white;
    text-align: center;
}

.fc-v-event .fc-event-main-frame {
    display: flex;
    flex-direction: column;
    height: auto;
}

/* Vertical event styling */
.fc-v-event {
    border-radius: 12px !important;
    margin: 5px !important;
    font-weight: bold;
    font-size: 12px;
}

/* Toolbar styling */
.fc .fc-toolbar {
    margin-bottom: 20px;
}

.fc-toolbar-title {
    font-size: 18px;
    font-weight: bold;
    color: #343a40;
}

.fc-toolbar-chunk {
    display: flex;
    align-items: center;
}

/* Event title alignment */
.fc .fc-event-title {
    text-align: center;
}

/* Event border radius */
.fc-timegrid-event.fc-event {
    border-radius: 15px !important;
}

/* Custom break styling */
.fc .lunch-break {
    background-color: #3d63ac !important;
    color: white !important;
    font-size: 14px !important;
    font-weight: bold !important;
    border: none !important;
}

/* Adjustments for mobile responsiveness */
@media (max-width: 768px) {
    .fc .fc-timegrid-slot {
        height: 100px;
        /* Reduced height for smaller screens */
    }

    .fc-timegrid-event .fc-event-main {
        font-size: 10px;
    }

    .fc-toolbar-title {
        font-size: 16px;
    }
}