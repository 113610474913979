.studentlistdiv {
    height: 380px;
    overflow-y: scroll;
}

.stepper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px dashed #d1d1d1;
    padding: 10px;
    /* gap: 10px; */
}

.step {
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: white;
    text-align: center;
    background-color: blue;
    padding: 5px;
    box-sizing: border-box;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}

.step:not(.active) .circle {
    background-color: #e0e0e0;
    color: #555;
}

.line {
    flex-grow: 1;
    height: 3px;
    background-color: #e0e0e0;
}

.circle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.step:not(.active) .circle {
    background-color: #e0e0e0;
    color: #555;
}

.step.active .circle {
    background-color: green;
    font-size: 18px;
}

.step.active .circle::after {
    content: '✔';
    /* Tick icon */
    font-size: 20px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    transform: translate(-50%, -50%);
}

.label {
    margin-top: 5px;
    font-size: 14px;
    color: #333;
    text-align: center;
    word-wrap: break-word;
}