@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  display: none
}


@tailwind base;
@tailwind components;
@tailwind utilities;