@import url('https://fonts.googleapis.com/css2?family=Ephesis&family=Josefin+Sans:ital,wght@1,700&family=Nova+Square&family=Poppins&family=Smooch&display=swap');

.Quiz {
    width: 100vw;
    height: 100vh;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Quicksand', sans-serif;
}

.Quiz1 {
    width: 100%;
    height: 100%;
    /* background-color: green; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Quiz-sidenavbar {
    width: 20%;
    height: 100%;
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #151515;
    color: white;
    padding: 10px;
}

.logo {
    width: 90%;
    height: auto;
    margin: 10px 0px;
}

.logo img {
    width: 100%;
    height: 100%;
}

.navbar-list {
    width: auto;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 20px;
    margin-right: 10px;
    line-height: 40px;
    /* background-color: aqua; */
}

.navbar-list h2 {
    transition: 0.7s;
}

.navbar-list h2:hover {
    color: yellow;
}

.logout-btn {
    width: 100%;
    height: auto;
    background-color: white;
    color: black;
    padding: 20px;
    border-radius: 10px;
}

.quiz-program {
    width: 100%;
    height: 100%;
    /* background-color: blue; */
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    /* padding: 20px; */
    padding: 0px 30px;
}

.quiz-program h1 {
    font-size: 20px;
    font-weight: bolder;
}

.quiz-user {
    width: 100%;
    height: auto;
    /* background-color: aquamarine; */
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px 0px;
}

.quiz-user h1 {
    font-size: 16px;
    margin: 0px 10px;
}

.std-img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.down-icon {
    font-size: 20px;
}

.std-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Quiz-grid {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    margin-top: 20px;
}

.Quiz-grid .grid-box {
    border: 1px solid #dcdcdc91;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    background-color: #dcdcdc91;
    border-radius: 10px;
    color: gray;
}

.grid-box1 {
    border: 1px solid #dcdcdc91;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
}

.grid-box1 h2 {
    color: gray;
    font-size: 20px;
    font-weight: bolder;
}



.quiz-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 440px;
    height: 250px;
    box-shadow: 0 8px 32px 0 gray;
    border-radius: 5px;
    padding: 30px;
    margin: 20px 0px;
}

.quiz-boxnew {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-shadow: 0 8px 32px 0 gray;
    border-radius: 5px;
    padding: 30px;
    margin: 20px 0px;
    width: 70%;
}

.boxnewview {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-shadow: 0 8px 32px 0 gray;
    border-radius: 5px;
    padding: 30px;
    margin: 20px 0px;
    width: 80%;
}

.quiz-box h3 {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
}

.quiz-box p {
    font-size: 12px;
}

.quiz-box input {
    width: 100%;
    height: 40px;
    border: 1px solid #dcdcdc91;
    border-radius: 5px;
    margin: 5px 0px;
    padding: 10px;
}

.quiz-box button {
    width: 100%;
    height: 40px;
    background-color: #000000;
    color: white;
    margin: 10px 0px;
    border-radius: 5px;
}

.Quiz-add {
    width: 80%;
    height: 100px;
    border: 1px dotted gray;
    margin: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;

}

.Quiz-add h1 {
    color: gray;
}

.quiz-program h2 {
    font-size: 15px;
    font-weight: bold;
}

.input-bordered {
    height: 40px;
    border: 1px solid #dcdcdc91;
    border-radius: 5px;
    margin: 5px 0px;
    padding: 10px;
}

table,
th,
td {
    border: 1px solid black;
}